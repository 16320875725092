import { GetStaticProps } from 'next';
// import type { NextPage } from 'next';
// import Link from 'next/link';
// import { useRouter } from 'next/router';
import { useEffect, useState, useRef } from 'react';

import gsap from 'gsap/dist/gsap';

import { NEXTJS_REVALIDATE_PAGE_IN_SECONDS } from 'siteConfig';

// import { useAppContext } from 'context/AppContext';

import { MetaDataType } from 'components/metaData/MetaData';
import { MainHeaderPlaceholder } from 'components/mainHeader/MainHeader';
import { Logo } from 'components/logo/Logo';
import { Grid } from 'components/grid/Grid';

import { GlobalContentContextType, useGlobalContentContext } from 'context/GlobalContentContext';
import { getGlobalContentContext } from 'drf/getGlobalContentContext';

import { useWindowSize } from 'hooks/useWindowSize';
import { getPrivateAPI, getPublicAPI, isDesktopWidth } from 'utils/helpers';
import { widont } from 'utils/typography';

import style from './index.module.scss';

type ScrollType = {
  direction: 'up' | 'down' | undefined;
  distance: number;
  y: number;
};

export type HomePageType = {
  title: string;
  introduction: string;
  meta_data?: MetaDataType;
};

type Props = {
  page: HomePageType;
  meta_data: MetaDataType;
  globalContentContextData: GlobalContentContextType;
};

const HomePage = (props: Props) => {
  const { page, globalContentContextData } = props;
  const { setData } = useGlobalContentContext();
  const windowSize = useWindowSize();
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  const svgHeight = 495;
  const svgWidth = 1404;

  const titleRef = useRef<SVGSVGElement>(null);
  const introRef = useRef<HTMLDivElement>(null);
  const footerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setData(globalContentContextData);
  }, [globalContentContextData]);

  useEffect(() => {
    const desktop = isDesktopWidth(windowSize.width);
    setIsDesktop(desktop);
  }, [windowSize.width]);

  useEffect(() => {
    window.scroll(0, 0);
    if (!isDesktop || !titleRef.current || !introRef.current || !footerRef.current) return;
    const titleElement = titleRef.current;
    const introElement = introRef.current;
    const footerElement = footerRef.current;
    const footerRect = footerElement.getBoundingClientRect();
    let flip = true;
    let requestedFlip = false;
    let scroll: ScrollType = { direction: 'up', distance: 0, y: 0 };
    let delayInit = 2.7;
    let delayDirection = 0.7;
    let delayOffset = 0.2;

    gsap.set(titleElement, { y: flip ? window.innerHeight : 0 });
    gsap.set(introElement, { y: flip ? window.innerHeight : 0 });
    gsap.set(footerElement, { y: flip ? -window.innerHeight : 0 });

    const animateFlip = () => {
      flip = requestedFlip;
      gsap.to(titleElement, {
        y: flip ? window.innerHeight : 0,
        duration: 0.8,
        delay: delayInit + (scroll.direction == 'up' ? delayDirection : 0) + (scroll.direction == 'down' ? delayOffset : 0),
        overwrite: true,
        ease: 'power1.inOut',
      });
      gsap.to(introElement, {
        y: flip ? window.innerHeight : 0,
        duration: 0.8,
        delay: delayInit + (scroll.direction == 'up' ? delayDirection : 0) + (scroll.direction == 'up' ? delayOffset : 0),
        overwrite: true,
        ease: 'power1.inOut',
      });
      gsap.to(footerElement, {
        y: flip ? -window.innerHeight : 0,
        duration: 0.8,
        delay: delayInit + (scroll.direction == 'down' ? delayDirection : 0),
        overwrite: true,
        ease: 'power1.inOut',
      });

      gsap.to(footerElement.querySelector('[class*=Logo___ll]'), {
        height: footerRect.width * (svgHeight / svgWidth),
        duration: 0.7,
        delay: 2,
        ease: 'power1.inOut',
        overwrite: true,
      });
      delayInit = 0
    };

    animateFlip();

    // const onScroll = (e: Event) => {
    //   const previousScroll = scroll;
    //   const y = window.scrollY;
    //   const direction = window.scrollY > previousScroll.y ? 'down' : 'up';
    //   scroll = {
    //     direction: direction,
    //     distance:
    //       direction !== previousScroll.direction
    //         ? 0
    //         : Math.abs(y - previousScroll.y) + previousScroll.distance,
    //     y: y,
    //   };

    // };
    // window.addEventListener('scroll', onScroll);
    // return () => {
    //   window.removeEventListener('scroll', onScroll);
    // };
  }, [isDesktop, titleRef.current, introRef.current, footerRef.current]);

  return (
    <article className={`${style.container} `}>
      {!isDesktop ? <MainHeaderPlaceholder blank={true} excludeGrid={true} /> : null }
      <Grid reverse={true} />
      <header className={`${style.pageHeader}`}>
        {/* this says 'Moller Architects' but the font is light  so tis an svg */}
        <svg
          width="1141"
          height="454"
          preserveAspectRatio="xMinYMin"
          viewBox="0 0 1141 454"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={style.mollerArchitects}
          ref={titleRef}
        >
          <path
            d="M3.60004 200H22.64V27.2401H23.2L91.8 200H109.72L178.04 27.2401H178.6V200H197.64V0.0800781H169.64L100.76 175.36L31.6 0.0800781H3.60004V200Z"
            fill="white"
          />
          <path
            d="M289.482 51.3201C245.242 51.3201 221.162 86.3201 221.162 127.76C221.162 169.2 245.242 204.2 289.482 204.2C333.722 204.2 357.802 169.2 357.802 127.76C357.802 86.3201 333.722 51.3201 289.482 51.3201ZM289.482 66.1601C323.362 66.1601 340.162 96.9601 340.162 127.76C340.162 158.56 323.362 189.36 289.482 189.36C255.602 189.36 238.802 158.56 238.802 127.76C238.802 96.9601 255.602 66.1601 289.482 66.1601Z"
            fill="white"
          />
          <path d="M378.708 200H396.348V0.0800781H378.708V200Z" fill="white" />
          <path d="M424.788 200H442.428V0.0800781H424.788V200Z" fill="white" />
          <path
            d="M592.108 132.52C593.508 91.9201 575.028 51.3201 528.548 51.3201C482.628 51.3201 463.308 89.6801 463.308 127.76C463.308 168.92 482.628 204.2 528.548 204.2C564.948 204.2 583.988 185.16 591.548 152.4H573.908C568.308 174.24 554.308 189.36 528.548 189.36C494.668 189.36 481.228 158.28 480.948 132.52H592.108ZM480.948 117.68C483.748 91.3601 499.708 66.1601 528.548 66.1601C557.108 66.1601 573.628 91.0801 574.468 117.68H480.948Z"
            fill="white"
          />
          <path
            d="M610.183 200H627.823V123C627.823 93.0401 649.663 69.5201 681.583 71.2001V53.5601C655.543 52.4401 635.943 66.1601 626.983 89.4001H626.423V55.5201H610.183V200Z"
            fill="white"
          />
          <path
            d="M0.0400391 449H20.48L44.56 386.84H135.56L159.92 449H180.36L101.96 249.08H80.68L0.0400391 449ZM90.48 268.12L129.12 370.6H50.44L90.48 268.12Z"
            fill="white"
          />
          <path
            d="M189.567 449H207.207V372C207.207 342.04 229.047 318.52 260.967 320.2V302.56C234.927 301.44 215.327 315.16 206.367 338.4H205.807V304.52H189.567V449Z"
            fill="white"
          />
          <path
            d="M386.926 349.88C383.006 316.84 359.206 300.32 328.406 300.32C284.166 300.32 260.086 335.32 260.086 376.76C260.086 418.2 284.166 453.2 328.406 453.2C360.606 453.2 383.286 431.92 388.046 397.2H370.406C368.166 421.28 350.246 438.36 328.406 438.36C294.526 438.36 277.726 407.56 277.726 376.76C277.726 345.96 294.526 315.16 328.406 315.16C351.366 315.16 364.526 328.32 369.286 349.88H386.926Z"
            fill="white"
          />
          <path
            d="M406.681 449H424.321V364.72C424.881 336.44 441.681 315.16 469.681 315.16C498.241 315.16 506.361 333.92 506.361 358.84V449H524.001V356.04C524.001 321.6 511.681 300.32 471.081 300.32C450.641 300.32 431.321 312.08 424.881 329.44H424.321V249.08H406.681V449Z"
            fill="white"
          />
          <path
            d="M552.032 449H569.672V304.52H552.032V449ZM552.032 277.36H569.672V249.08H552.032V277.36Z"
            fill="white"
          />
          <path
            d="M625.831 261.12H608.191V304.52H582.991V319.36H608.191V418.2C607.911 442.84 616.031 450.68 639.551 450.68C644.871 450.68 649.911 450.12 655.231 450.12V435C650.191 435.56 645.151 435.84 640.111 435.84C627.511 435 625.831 428.28 625.831 416.8V319.36H655.231V304.52H625.831V261.12Z"
            fill="white"
          />
          <path
            d="M796.603 381.52C798.003 340.92 779.523 300.32 733.043 300.32C687.123 300.32 667.803 338.68 667.803 376.76C667.803 417.92 687.123 453.2 733.043 453.2C769.443 453.2 788.483 434.16 796.043 401.4H778.403C772.803 423.24 758.803 438.36 733.043 438.36C699.163 438.36 685.723 407.28 685.443 381.52H796.603ZM685.443 366.68C688.243 340.36 704.203 315.16 733.043 315.16C761.603 315.16 778.123 340.08 778.963 366.68H685.443Z"
            fill="white"
          />
          <path
            d="M934.238 349.88C930.318 316.84 906.518 300.32 875.718 300.32C831.478 300.32 807.398 335.32 807.398 376.76C807.398 418.2 831.478 453.2 875.718 453.2C907.918 453.2 930.598 431.92 935.358 397.2H917.718C915.478 421.28 897.558 438.36 875.718 438.36C841.838 438.36 825.038 407.56 825.038 376.76C825.038 345.96 841.838 315.16 875.718 315.16C898.678 315.16 911.838 328.32 916.598 349.88H934.238Z"
            fill="white"
          />
          <path
            d="M982.273 261.12H964.633V304.52H939.433V319.36H964.633V418.2C964.353 442.84 972.473 450.68 995.993 450.68C1001.31 450.68 1006.35 450.12 1011.67 450.12V435C1006.63 435.56 1001.59 435.84 996.553 435.84C983.953 435 982.273 428.28 982.273 416.8V319.36H1011.67V304.52H982.273V261.12Z"
            fill="white"
          />
          <path
            d="M1135.69 346.8C1134.57 315.44 1111.61 300.32 1082.77 300.32C1057.29 300.32 1028.45 311.52 1028.45 340.92C1028.45 365.56 1045.81 373.4 1068.77 379.56L1091.45 384.6C1107.41 388.52 1123.08 394.12 1123.08 410.92C1123.08 431.08 1100.12 438.36 1083.6 438.36C1060.08 438.36 1042.45 426.04 1041.05 402.24H1023.41C1026.21 437.52 1049.17 453.2 1082.77 453.2C1109.65 453.2 1140.72 441.16 1140.72 409.8C1140.72 384.6 1119.73 372.84 1099.57 369.76L1076.05 364.44C1064.01 361.36 1046.08 355.76 1046.08 340.08C1046.08 321.32 1064.56 315.16 1080.24 315.16C1100.4 315.16 1117.21 324.96 1118.05 346.8H1135.69Z"
            fill="white"
          />
        </svg>
        <div
          className={`${style.introduction}`}
          dangerouslySetInnerHTML={{ __html: widont(page.introduction) }}
          ref={introRef}
        ></div>
      </header>
      <div ref={footerRef} className={`${style.pageFooter}`}>
        <Logo listenerRef={footerRef} rectRef={footerRef} location="home" />
      </div>
    </article>
  );
};
/* This gets called at build time, to generate the static props,
   revalidate parameter defines the cache life */
export const getStaticProps: GetStaticProps = async (context) => {
  const { preview, params } = context;
  // const globalContentContextRequest = await fetch(`${getPublicAPI()}/globalcontentcontext`);
  // const globalContentContextData = await globalContentContextRequest.json();
  const globalContentContextData = await getGlobalContentContext(preview);

  const pageRequest = await fetch(`${getPrivateAPI()}/homepage/`);
  const pageData = await pageRequest.json();

  const props: Props = {
    page: pageData,
    meta_data: Object.assign({}, globalContentContextData.site_meta_data, pageData.meta_data),
    globalContentContextData: globalContentContextData,
  };
  return {
    props: props,
    revalidate: NEXTJS_REVALIDATE_PAGE_IN_SECONDS,
  };
};

export default HomePage;
